import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import SeoHelmet from "../components/SeoHelmet";
import MainSlider from "../components/MainSlider";
import AboutClaims from "../components/AboutClaims";
import AboutUs from "../components/AboutUs";

const SetupMeetingPage = () => (
  <Layout spa={false}>
    <SeoHelmet title="Umów spotkanie" />

    <main className="pt-20">
      <iframe className="w-full aspect-video"
              src="https://app.simplymeet.me/odszkodowanie-org/weryfikacja-roszczenia?is_widget=1&view=compact"
              frameBorder="0" scrolling="yes"></iframe>
    </main>


  </Layout>
)

export default SetupMeetingPage
